<template>
  <div class="container">
    <section class="main w12">
      <nav>当前位置：<router-link :to="'home'">首页</router-link> > <span>出版社详情</span></nav>
      <div class="detail_main flex">
        <img :src="detail.logo ? detail.logo : noImg"/>
        <div class="right_detail">
          <p>{{detail.name}}</p>
          <p>{{detail.alias}}</p>
          <p v-show="detail.subject"><span>学科：<label>{{detail.subject}}</label></span></p>
          <p>
            <span v-show="detail.publisheertypename" class="mr15">出版社类型：<label>{{detail.publisheertypename}}</label></span>
            <span v-show="detail.languages" class="mr15">出版语种：<label>{{detail.languages}}</label></span>
            <span v-show="detail.reader" class="mr15">读者群：<label>{{detail.reader}}</label></span>
          </p>
          <p v-show="detail.domainname"><span>网址：<label class="alink" @click="toLink">{{detail.domainname}}</label></span></p>
          <p v-show="detail.desc"><span>简介：<label>{{detail.desc}}</label></span></p>
        </div>
      </div>
      <div class="top">
        <div class="search_div flex">
          <div class="search flex">
            <el-input
              placeholder="请输入检索内容"
              v-model="searchVal"
              clearable
              @keyup.enter.native="toSearch"
            ></el-input>
            <span @click="toSearch"><i class="el-icon-search"></i></span>
          </div>
        </div>
      </div>
      <!-- main -->
      <div class="content_main flex" v-loading="loading">
        <LeftMenu
          :left="leftData"
          :searchVal='searchLeftFlag'
          @childByValue="childByValue"
          class="left_main"
        ></LeftMenu>
        <div class="right_main">
          <div class="module_one flex_a">
            <span class="total"
              >共
              <template v-if="searchVal && searchFlag">
                检索到<label class="mr15">"{{ searchVal }}"</label>
              </template>
              <label>{{ total }}</label> 条记录
            </span>
          </div>
          <div class="noData" v-if="total == 0">
            <img src="@/assets/images/no-content.png"/>
            <p>暂无相关纪录</p>
          </div>

          <div class="module_two" v-if="total > 0">
            <ul class="flex_a">
              <li
                :class="sortIndex == index ? 'checked flex_a' : 'flex_a'"
                v-for="(item, index) in orderactions"
                :key="index"
                @click="changeSort(index)"
              >
                {{ item.text }}
                <i
                  v-if="item.value"
                  :class="
                    !item.flag
                      ? 'iconfont icon-icon_xiangxiajiantoucuxiao'
                      : 'iconfont icon-xiangshangjiantoucuxiao'
                  "
                ></i>
              </li>
            </ul>
          </div>

          <div class="module_three">
            <ul>
              <li class="flex" v-for="(item, index) in list" :key="index">
                <div class="mgcIndex">
                  <p>{{ (pageno - 1) * pagesize + index + 1 }}</p>
                </div>
                <div class="rightInfo">
                  <div class="flex">
                    <div class="img">
                      <img
                        @click="toBookDetail(item.ruid)"
                        :src="
                          item.code == 1
                            ? item.coverruid
                            : item.coverruid
                            ? `${fileBaseUrl}/api/v1/fileredirect/file?ruid=` +
                              item.coverruid
                            : noImg
                        "
                      />
                    </div>
                    <div class="info">
                      <p class="line2" @click="toBookDetail(item.ruid)" v-html="item.title"></p>
                      <p v-html="item.titlecn"></p>
                      <p v-show="item.author">
                        <span>作者信息：</span><label v-html="item.authorinfo"></label>
                      </p>
                      <p>
                        <span class="mr15" v-show="item.publisher" @click="toPublisher(item, index)">
                          出版社：<label
                            class="alink"
                            v-html="item.publisher"
                          ></label
                        ></span>
                        <span v-show="item.publishyear"
                          >出版时间：<label>{{ item.publishyear }}</label></span
                        >
                      </p>
                      <p>
                        <span class="mr15"
                          >ISBN：<label v-html="item.isbn"></label
                        ></span>
                        <span v-show="item.edition"
                          >版次：<label>{{ item.edition }}</label></span
                        >
                      </p>
                      <p>
                        <span
                          class="mr15"
                          v-show="item.binding && item.binding != 'null'"
                          >装帧：<label>{{ item.binding }}</label></span
                        >
                        <span class="mr15" v-show="item.pages"
                          >页码：<label>{{ item.pages }}</label></span
                        >
                        <span v-show="item.language"
                          >语种：<label>{{ item.language }}</label></span
                        >
                      </p>
                      <p v-show="item.price">
                        <span>价格：<label v-html="item.price"></label></span>
                      </p>
                      <!-- <div class="tags">
                        <span
                          v-for="(element, idx) in item.relatedlist.tags"
                          :key="idx"
                          >{{ element }}</span
                        >
                      </div> -->
                    </div>
                    <!-- <div class="btnList">
                      <span
                        :class="btnList.ruid == 'iobl_data_recommend' ? 'checked' : ''"
                        v-for="(btnList, btnIdx) in item.recommendList"
                        :key="btnIdx"
                        @click="btnClick(btnList)">{{ btnList.text }}</span>
                    </div> -->
                  </div>
                </div>
              </li>
            </ul>
            
          </div>
          <el-pagination
            v-if="total > 0"
            background
            @current-change="handleCurrentChange"
            :page-size="pagesize"
            :current-page="pageno"
            layout="prev, pager, next, jumper"
            :total="total > 10000 ? 10000 : total"
          >
          </el-pagination>
        </div>
      </div>
    </section>
    <el-dialog :close-on-click-modal="false" title="不感兴趣理由" :visible.sync="dialogNoVisible" width="30%">
      <el-select v-model="noVal" placeholder="请选择">
        <el-option
          v-for="item in optionsNo"
          :key="item.id"
          :label="item.reason"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNoVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmitNo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import urlSet from "@/requests/url";
import LeftMenu from "./PublisherLeftMenu";

require("@/assets/styles/bookList.scss");
require("@/assets/styles/reset.css");
export default {
  name: "choiceBook",
  data() {
    return {
      loading: false,
      pageno: 1,
      pagesize: 5,
      orderfield: "",
      ordertype: "",
      tabs: [],
      tabsIdx: 3,
      leftData: [],
      orderactions: [],
      sortIndex: 0,
      total: 0,
      list: [],
      btnFlag: false, //按钮加载中标识
      checkList: [], //多选框选中的数据
      noImg: require("@/assets/images/no-image.png"),
      searchVal: "",
      searchFlag: false,
      searchLeftFlag: false,//搜索以后 左侧距和清空条件 标识
      childValue: [],
      fileBaseUrl: "",
      linkUrl: "",
      dialogNoVisible: false, //不感兴趣弹框
      optionsNo: [],
      noVal: "",
      btnItem: {},
      optionsVal:'',
      customer: '',
      detail: {},
      
    };
  },
  components: {
    LeftMenu,
  },
  mounted() {
    this.customer = this.$route.query.customer
    this.fileBaseUrl = urlSet.fileUrl;
    this.getList();
    this.getDetail()
  },
  methods: {
    getDetail() {
      let postBody = {
        customer: this.customer,
        cloudfair: '',
      }
      this.$http({
        url: `/api/v1/datasearch/cloudfair_publisher_detail?customer=${this.customer}&cloudfair=undefined`,
        method: "POST",
      }).then((res) => {
        this.btnFlag = false;
        if (res.cxajaxrc == 0) {
          this.detail = res.returnvalue.customerinfo
        }
      });
      
    },
    toSearch() {
      this.ordertype = "";
      this.orderfield = "";
      this.tabsIdx = 3;
      this.sortIndex = 0;
      this.linkUrl = "";
      this.pageno = 1;
      this.childValue = []
      this.searchLeftFlag = true
      if (this.searchVal) {
        this.searchFlag = true;
      } else {
        this.searchFlag = false;
      }
      this.getList();
    },
    getList() {
      this.loading = true;
      let array = [];
      let customerArray = []
      let str = ''
      if(this.customer){
        customerArray.push({
          "logicalop":"and","fieldname":"10400005","relationop":"equal","valueType":"S","word": this.customer
        })
        array = customerArray
      }
      this.childValue.forEach((element) => {
        // body入参重拼
        if (element.filterflag == 1) {
          let postbody = {
            logicalop: "and",
            fieldname: element.filterfield,
            relationop: "raw",
            word: element.ruid,
          };
          array.push(postbody);
        }

        if (element.filterflag == 100) {
          element.filterfield.forEach((element) => {
            array.push(element);
          });
        }
        if(element.urlappend){
          str = str + element.urlappend
        }
      });
      // 搜索
      const val = this.searchVal.trim()
      if (val) {
        let postbody = {
          logicalop: "and",
          fieldname: "10100001,10110001,15900011,10400001,281450001",
          relationop: "like",
          word: val,
        };
        array.push(postbody);
      }

      this.$http({
        url: `${
          this.linkUrl
            ? this.linkUrl
            : "/api/v1/datasearch/released/iobl_tab_all"
        }?ai=u&pageno=${this.pageno - 1}&pagesize=${this.pagesize}&orderfield=${
          this.orderfield
        }&ordertype=${this.ordertype}${str}`,
        method: "POST",
        data: {
          filter: array,
          search: customerArray
        },
      }).then((res) => {
        this.loading = false;
        this.tabs = []


        res.returnvalue.tabs.forEach(element => {
          if(element.name != '不感兴趣' && element.name != '不通过'){
            this.tabs.push(element)
          }
        });

        this.leftData = res.returnvalue.left;
        this.leftData.forEach((element) => {
          element.newArrays = [];
        });
        if (!this.orderfield) {
          this.orderactions = [];
          res.returnvalue.orderactions.forEach((element) => {
            if (element.value) {
              this.orderactions.push(element);
            }
          });
          this.orderactions.unshift({ text: "默认排序", value: "" }); //添加默认排序
        }
        this.list = res.returnvalue.recordlist;
        if (this.list) {
          this.list.forEach((element,index) => {
            element.recommendList = []
            if(element.rightaction){
               element.rightaction.forEach(btnItem => { //出版社详情不需要不感兴趣
                if(btnItem.ruid != 'iobl_data_addignoreddata'){
                  this.list[index].recommendList.push(btnItem)
                }
              });
            }
            element.code = 0;
            if (element.coverruid) {
              if (
                element.coverruid.substr(0, 7) == "http://" ||
                element.coverruid.substr(0, 8) == "https://"
              ) {
                element.code = 1;
              }
            }

          });
        }
        console.log(this.list)
        this.total = res.returnvalue.recordcount;
      });
    },
    changeSort(index) {
      if (index == this.sortIndex) {
        this.orderactions[index].flag = !this.orderactions[index].flag;
      }
      this.sortIndex = index;
      this.ordertype = this.orderactions[index].flag ? "A" : "D";
      this.orderfield = this.orderactions[index].value;
      this.pageno = 1;
      if (!this.orderfield) {
        //默认排序
        this.ordertype = "";
      }

      this.getList();
    },
    toPublisher(item, index) {
      const code = item.publisherinfo.customercode
      let url = this.$router.resolve({
        name: 'PublisherDetail',
        query: { customer: code }
      });
      window.open(url.href, '_blank')
    },
    handleChangTabs(index, url) {
      this.linkUrl = url;
      this.tabsIdx = index;
      this.pageno = 1;
      this.getList();
    },
    // 子组件传过来的筛选条件
    childByValue(childValue) {
      this.pageno = 1;
      this.childValue = childValue;
      this.searchLeftFlag = false
      this.getList();
    },
   
    handleChangeMaction(val,index) {
      this.optionsVal = val
      this.list[index].mactionVal = val
      this.$forceUpdate()
      
    },
    // 修改
    updateSbumitNo(url) {
      if(!this.optionsVal) {
        this.$message.error("请选择不感兴趣理由");
        return
      }
      this.$http({
        url: `${url}${this.optionsVal}`,
        method: "GET",
      }).then((res) => {
        if (res.cxajaxrc == 0) {
          this.optionsVal = ''
          this.$message.success("修改不感兴趣成功");
          this.getList();
        }
      });

    },
    handleCurrentChange(val) {
      this.pageno = val;
      this.getList();
    },
    btnClick(item) {
      this.btnItem = item;
      if (item.ruid == "iobl_data_recommend") {
        // 采选
        this.$confirm("确定要采选所选图书吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http({
              url: `${item.url}`,
              method: "POST",
              data: item.post,
            }).then((res) => {
              if (res.cxajaxrc == 0) {
                this.$message.success("采选成功");
                this.getList();
              }
            });
          })
          .catch(() => {});
      }
      if (item.ruid == "iobl_data_addignoreddata") {
        //不感兴趣
        this.noVal = "";
        this.dialogNoVisible = true;
        this.$http({
          url: `/api/v1/user/config/ignoredreason/list?userRuid=${localStorage.getItem(
            "userruid"
          )}`,
          method: "GET",
        }).then((res) => {
          if (res.cxajaxrc == 0) {
            this.optionsNo = res.returnvalue;
          }
        });
      }
      if(item.ruid == 'iobl_data_cancelrecommend') { //取消采选
        this.$http({
          url: `${item.url}`,
          method: "GET",
        }).then((res) => {
          if (res.cxajaxrc == 0) {
             this.$message.success("取消采选成功");
             this.getList();
          }
        });
      }
      if(item.ruid == "iobl_data_cancelignored") {
        this.$http({
          url: `${item.url}`,
          method: "GET",
        }).then((res) => {
          if (res.cxajaxrc == 0) {
             this.$message.success("移出成功");
             this.getList();
          }
        });
      }
    },
    handleSubmitNo() {
      if (!this.noVal) {
        this.$message.error("请选择不通过理由");
        return;
      }
      this.$http({
        url: `${this.btnItem.url}${this.noVal}`,
        method: "GET",
      }).then((res) => {
        if (res.cxajaxrc == 0) {
          this.dialogNoVisible = false;
          this.$message.success("添加至不感兴趣成功");
          this.getList();
        }
      });
    },
    toBookDetail(id) {
      let _url = this.$router.resolve({
          path: '/BookDetail',
              query:{
              id: id,
              recommendType:1
          }
      });
      // 打开一个新的页面     
      window.open(_url.href, '_blank')
    },
    toLink() {
      window.open(this.detail.domainname)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-select {
  flex: 1;
  width: 100%;
}
.container {
  width: 1200px;
  margin: 0 auto;
  .main {
    nav{ margin-top:20px;
      font-size: 15px;
      color: #666666;
      a{
        color: #666666;
      }
    }
    .detail_main{
      background: rgb(238, 238, 238);
      padding: 30px;
      margin: 20px 0;
      font-size: 15px;
      img{
        height: 420px;
        width: 300px;
        margin-right: 20px;
      }
      .right_detail{
        flex: 1;
        p{
          color: #666666;
          margin-bottom: 15px;
          line-height: 23px;
          span{
            font-weight: bold;
            label{
              font-weight: normal;
            }
            .alink{
              color: $mainColor;
              cursor: pointer;
              text-decoration: underline;
            }
          }
          &:nth-of-type(1){
            font-size: 19px;
            font-weight: bold;
            color: #333;
          }
        }
      }
    }
    .top {
      background: #fff;
      .search_div {
        padding: 10px 30px;
        // border-bottom: 1px solid #dddddd;
        .el-input {
          width: 280px;
        }
        /deep/ .el-input__inner {
          border-radius: 0;
        }
        .el-button {
          border: 1px solid $mainColor;
          color: $mainColor;
          border-radius: 0;
          margin-left: auto;
        }
        .search {
          span {
            font-size: 20px;
            color: #fff;
            width: 70px;
            height: 40px;
            line-height: 40px;
            background: $mainColor;
            text-align: center;
            cursor: pointer;
          }
        }
      }
      .tab {
        padding: 15px 30px;
        ul {
          background: #f7f7f7;
          border-bottom: 1px solid $mainColor;
          li {
            padding: 10px 20px;
            cursor: pointer;
            font-size: 15px;
          }
          .checked {
            color: #fff;
            background: $mainColor;
          }
        }
      }
    }
    .content_main {
      margin-top: 10px;
      align-items: flex-start;
      font-size: 15px;
      .left_main {
        width: 280px;
        margin-right: 10px;
      }
    }
  }
}
</style>
