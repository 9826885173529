<template>
  <div class="container">
    <div class="element" v-for="(item,index) in leftData" :key="index">
      <ul class="flex" v-if="item.name == '学科' && (item.items.length > 0 ? item.items[0].breadcrumb : '')">
        <li :class="tabIdx == subjectIdx ? 'checked line1' : 'line1'" @click="changeTab(index,subjectIdx)" v-for="(element,subjectIdx) in item.items" :key="subjectIdx">
          {{element.breadcrumb[0].name}}
        </li>
      </ul>
      <p v-else>{{item.name}}</p>
        <div class="tag-box">
          <p :class="element.checked ? 'flex_a checked' : 'flex_a'" v-for="(element,idx) in item.newArrays" :key="idx" @click="childClick(index,idx)">
            <span class="line2">{{element.name}}</span>
            <span>({{element.result}})</span>
            <i v-if="element.checked" class="iconfont icon-shanchu close-icon" @click.stop="del(element,index)"></i>
          </p>
          <ul class="children" v-if="item.children">
            <li :class="childItem.checked ? 'flex_a checked' : 'flex_a'" v-for="(childItem,cIdx) in item.children" :key="cIdx" @click="handleChildrenNode(index,cIdx)">
              <span class="line2">{{childItem.name}}{{childItem.checked}}</span>
              <span>({{childItem.result}})</span>
              <i v-if="childItem.checked" class="iconfont icon-shanchu close-icon"></i>
            </li>
          </ul>

          <div class="setting" v-if="item.type == 1 || item.type == 2">
            <i @click="loadingMore(index)" :class="item.type == 1 ? 'iconfont icon-icon_arrow_bottom checked' : 'iconfont icon-icon_arrow_bottom'"></i>
            <i @click="spliceMore(index)" :class="item.type == 2 ? 'iconfont icon-icon_arrow_top checked' : 'iconfont icon-icon_arrow_top'"></i>
          </div>
        </div>
    </div>
   
  </div>
</template>
 
<script>
export default {
  name: "drop",
  props:{
    left:{
      type: Array,
      default:() => {
          return []
      }
    },
    searchVal:{
      type: Boolean,
      defaule: false,
    }
  },
  watch:{
    left(val){ 
      // 搜索清空所选聚合
      if(this.searchVal) {
        this.childValue = []
        this.checkedItem = null
        this.curIndex = []
        this.leftData = []
      }
      val.forEach((element,index) => {
        element.nums = []
        if(element.items.length > 0){
          if(element.name == '学科' && element.items[0].breadcrumb){
            if(element.items[this.tabIdx].items){   
              element.items[this.tabIdx].items.forEach((item,idx) => {
                // 筛选出数量不为0 的
                if(item.result != 0){ 
                  element.nums.push(item)
                }
              })
            }
          }
          else{
            element.items.forEach((item,idx) => {
            // 筛选出数量不为0 的
              if(item.result != 0){ 
                element.nums.push(item)
              }
            });
          }
        }
        
      });
      this.leftData = val
      this.leftData.forEach((element,index) => {
        element.nums.forEach((item,idx) => {
          if(!item.ruid){
            element.nums.splice(idx,1)
          }
          this.childValue.forEach(childItem => {
            if(childItem.ruid == item.ruid){
              this.leftData[index].nums = []
              this.leftData[index].nums.push(item)
            }
          });
        });
        
      });
      console.log(this.leftData)
      
      this.getData()
    }
    
  },
  data() {
    return {
      curIndex: [], //存储点击的聚合
      leftData: [],//数据初始化
      childValue: [],//存储要传到父组件的聚合集合
      tabIdx: 0,
      checkedItem: null,//如果有子节点 存储
    };
  },
  mounted() {
  },
  methods: {
    changeTab(index, tabIdx) {
      this.tabIdx = tabIdx
      if(this.checkedItem){
        this.del(this.checkedItem, index)
      }
      this.checkedItem = null
      this.leftData.forEach(element => {
        element.newArrays = []
      });
      this.getData()
    },
    getData() {
      this.leftData.forEach((element,index) => {
        if(element.items.length > 0){
          if(element.name == '学科' && element.items[0].breadcrumb){
            element.nums = []
            element.children = []
            if(element.items[this.tabIdx].items){
              element.items[this.tabIdx].items.forEach((item,idx) => {  //tab切换 重新渲染
                // 筛选出数量不为0 的
                if(item.result != 0){ 
                  element.nums.push(item)
                }
                if(this.checkedItem && this.checkedItem.urlappend){
                  element.nums = []
                  element.nums.push(this.checkedItem)
                  if(item.result != 0){ 
                    element.children.push(item)
                  }
                }
              })
            }else{
              element.nums.push(this.checkedItem)
            }
          }
        }
        element.nums.forEach((item,index) => {
          if(element.name.indexOf('学科') != -1){ //学科类目下  接口url不需要拼链接
            this.$set(item,'subject',true)
          }
          if(index < 3){
            element.newArrays.push(item)
          }
        });
        if(element.nums.length > 3){
          element.type = 1
        }else{
          element.type = ''
        }
      });
     
      
      this.curIndex.forEach(element => {
          this.leftData[element].newArrays[0].checked = true
      });
    },
    // 加载更多
    loadingMore(index) {
     
      this.leftData[index].newArrays = this.leftData[index].nums
      this.leftData[index].type = 2
      this.$forceUpdate()
    },
    // 收起 更多
    spliceMore(index) {
      this.leftData[index].newArrays = []
      this.leftData[index].nums.forEach( (item,idx)=>{
        if(idx < 3){
          this.leftData[index].newArrays.push(item)
        }
      })
      this.leftData[index].type = 1
      this.$forceUpdate()
    },
    // 点击根据筛选条件查询
    childClick(index,idx) {
      this.curIndex.push(index)
      this.curIndex = [...new Set(this.curIndex)]
      // 防止重复
      this.childValue.push(this.leftData[index].newArrays[idx])
      for (var i = 0; i < this.childValue.length; i++) {
        for (var j = i + 1; j < this.childValue.length; j++) {
          if (this.childValue[i].ruid === this.childValue[j].ruid) {
            this.childValue.splice(j, 1);
            j = j - 1;
          }
        }
      }
      if(this.leftData[index].name == '学科' && this.leftData[index].items[0].breadcrumb){ //点击学科  保存点击的子节点
        this.checkedItem = this.leftData[index].nums[idx]
      }
      this.$emit('childByValue', this.childValue)
    },
    // 子节点点击
    handleChildrenNode(index,cIdx) {
      this.childValue.push(this.leftData[index].children[cIdx])
      for (var i = 0; i < this.childValue.length; i++) {
        for (var j = i + 1; j < this.childValue.length; j++) {
          if (this.childValue[i].ruid === this.childValue[j].ruid) {
            this.childValue.splice(j, 1);
            j = j - 1;
          }
        }
      }
      this.$emit('childByValue', this.childValue)
    },
    // 删除具体条件
    del(delItem,arrayIndex) {
      this.childValue.forEach((element,index) => {
        if(delItem.ruid == element.ruid){
          this.childValue.splice(index, 1);
        }
          if(delItem.urlappend){
            this.checkedItem = null
            this.childValue.splice(index, 1);
          }
        
      });
      this.curIndex.forEach((element,index) => {
        if(element == arrayIndex){
          this.curIndex.splice(index, 1);
        }
      });
      this.$emit('childByValue', this.childValue)
    }
  },
};
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  .element{
    padding: 15px;
    background: #FFF;
    font-size: 16px;
    &:last-of-type{
      margin: 0;
    }
    >p, >ul{
      border-bottom: 1px solid #E6E6E6;
      font-weight: bold;
      font-size: 18px;
      color: #333;
      padding-bottom: 15px;
    }
    >ul{
      li{
        color: #999999;
        cursor: pointer;
        text-align: center;
        flex: 1;
        &:nth-of-type(1){
          border-right: 1px solid #ccc;
          padding-right: 5px;
        }
        &:nth-of-type(2){
          padding-left: 10px;
        }
      }
      .checked{
        color: #333;
      }
    }
    >div{
      padding-top: 15px;
      .children{
        padding: 0 20px;
        margin-top: 10px;
        li{
          padding: 7px 0;
          cursor: pointer;
          font-size: 15px;
          &:hover{
            color: $mainColor;
          }
          i{
            margin-left: auto;
            color: $mainColor;
          }
          span{
            &:nth-of-type(1){
              max-width: 75%;
              line-height: 24px;
            }
            &:nth-of-type(2){
              color: #999;
            }
          }
        }
        .checked{
          color: $mainColor;
        }
      }
      p{
        margin-bottom: 15px;
        cursor: pointer;
        &:hover{
          span{
            &:nth-of-type(1){
              color: $mainColor;
            }
          }
        }
        &:last-of-type{
          margin: 0;
        }
        span{
          &:nth-of-type(1){
            max-width: 75%;
            line-height: 25px;
          }
          &:nth-of-type(2){
            color: #999;
          }
        }
        i{
          margin-left: auto;
          color: $mainColor;
        }
      }
      .checked{
        color: $mainColor;
      }
      .setting{
        text-align: center;
        margin-top: 10px;
        i{
          margin: 0 10px;
          cursor: pointer;
          color: #999999;
        }
        .checked{
          color: $mainColor;
        }
      }
    }
  }
}
.close-icon {
  font-size: 1.5rem;
}
.tag-box {
  background-color: #eee;
  padding: 10px;
}
</style>